//// Vendors ////
import AOS from 'aos';
import { vendorRellax } from './components/_vendor-rellax';
import { vendorSplide } from './components/_vendor-splide';

//// SITE functions ////
import { sitePreloader } from './components/_site-preloader';
//import { siteSearch } from './components/_site-search';

//// NAVBAR ////
import { navbarMenu } from './components/_navbar-menu';
import { navbarScroll } from './components/_navbar-scroll';

//// UTILS ////
import { utilSmoothScroll } from './components/_util-smoothscroll';

// DOM ready first
document.addEventListener(
    'DOMContentLoaded',
    function () {
        // AOS
        AOS.init({
            duration: 800,
            offset: 40
        });

        // Rellax
        vendorRellax();
        // Splide
        vendorSplide();

        // Site TBD
        //siteSearch();

        // Navbar
        navbarMenu();
        navbarScroll();

        // Utils
        utilSmoothScroll();
    },
    false
);

// window.load next
window.addEventListener('load', sitePreloader);
