// Hide me show me header
export function navbarScroll() {
    let didScroll;
    let lastScrollTop = 0;

    const delta = 5;
    const navbar = document.querySelector('#navbar');
    const navbarHeight = navbar.offsetHeight;

    window.addEventListener('scroll', () => {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        let st = window.pageYOffset;

        if (Math.abs(lastScrollTop - st) <= delta) return;

        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            navbar.classList.remove('nav-down');
            navbar.classList.add('nav-up');
        } else {
            // Scroll Up
            if (st + window.innerHeight < document.body.clientHeight) {
                navbar.classList.remove('nav-up');
                navbar.classList.add('nav-down');
            }
        }

        lastScrollTop = st;
    }

    // Simple "has it scrolled?"
    if (typeof navbar != 'undefined' && navbar != null) {
        let scrollEvent = function () {
            let top = window.pageYOffset || document.documentElement.scrollTop;
            if (top > 100) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', scrollEvent, false);
        window.addEventListener('load', scrollEvent, false);
    }
}
