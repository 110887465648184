import Splide from '@splidejs/splide';

export function vendorSplide() {
    const shows = document.getElementsByClassName('splide');

    if (!shows) {
        return;
    }

    for (var i = 0; i < shows.length; i++) {
        new Splide(shows[i]).mount();
    }
}
