// Preloader
export function sitePreloader() {
    const preloader = document.querySelector('#site-preloader');

    if (typeof preloader != 'undefined' && preloader != null) {
        if (preloader.classList.contains('loaded')) {
            preloader.classList.remove('loaded');
        }
        setTimeout(function () {
            preloader.classList.add('loaded');
        }, 600);
    }
}
