// Smooth scroll
export function utilSmoothScroll() {
    const links = document.querySelectorAll("a[href*='#']:not([href='#']):not([href='#0'])");

    for (const link of links) {
        link.addEventListener('click', clickHandler);
    }

    function clickHandler(e) {
        e.preventDefault();
        const href = this.getAttribute('href');
        const offsetTop = document.querySelector(href).offsetTop;

        scroll({
            top: offsetTop - 80,
            behavior: 'smooth'
        });
    }
}
