import Rellax from 'rellax';

export function vendorRellax() {
    const rellaxEl = document.querySelectorAll('.rellax').length > 0;

    if (rellaxEl) {
        const rellax = new Rellax('.rellax', {
            center: true
        });
        return rellax;
    } else {
        return;
    }
}
