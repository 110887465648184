// Site menu
export function navbarMenu() {
    const navbarBurger = document.querySelector('#navbar-burger');
    const navbarMenu = document.querySelector('#navbar-menu');

    navbarBurger.addEventListener('click', function () {
        navbarBurger.classList.toggle('active');

        if (navbarBurger.classList.contains('active')) {
            navbarBurger.setAttribute('aria-expanded', 'true');
            navbarMenu.classList.add('menu-open');
            document.documentElement.classList.add('noscroll');
        } else {
            navbarBurger.setAttribute('aria-expanded', 'false');
            navbarMenu.classList.remove('menu-open');
            document.documentElement.classList.remove('noscroll');
        }
    });
}
